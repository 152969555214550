import { memo, useEffect, useState } from "react";

const Profile = memo((props) => {
    const [ach, setAch] = useState([])
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function getTasks() {
            
            try {
                const req = await fetch(`https://api.spin2win.tech/GetAchievements/${window.Telegram.WebApp.initDataUnsafe?.user?.id}`, {
                    method: 'GET',
                    headers: {
                        'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                    }
                })
                if (req.ok) {
                    const res = await req.json();
                    setAch(await res)
                } else {
                    console.error("Failed to fetch data");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        getTasks();
    }, []);

    async function Claim(_id, img_url, title, description, cost, completed) {
        try {
            const req = await fetch(`https://api.spin2win.tech/ClaimAchievement/${window.Telegram.WebApp.initDataUnsafe?.user?.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Не забудьте добавить этот заголовок
                    'Check-String': window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : ""
                },
                body: JSON.stringify({
                    "_id": _id,
                    "img_url": img_url,
                    "title": title,
                    "description": description,
                    "cost": cost,
                    "completed": completed
                })
            });

            if (req.ok) {
                const res = await req.json();
                
                // Обновление состояния задач
                setAch((prevTasks) => 
                    prevTasks.map(task => 
                        task._id === _id ? { ...task, claimed: true } : task
                    )
                );
            } else {
                console.error("Failed to fetch data");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const incomeLevels = {
        "Новичок": 1,
        "Ученик": 2,
        "Инвестор": 3,
        "Трейдер": 4,
        "Стартапер": 5,
        "Гуру": 6,
        "Криптомиллионер": 7,
        "Элита": 8,
        "Форбс": 9,
        "Титан": 10
    };

    return (
    <div className="layout_pr">
        <div className="lay_pr">
        
            <div className="leag_wrap">
                <img src={window.Telegram.WebApp.initDataUnsafe?.user?.photo_url}/>
                <span className="leag">{props.data.league ? props.data.league : "Новичок"}</span>
            </div>
            <span className="Dos">Достижения</span>
        <div class="scroll-container-pr">
        <div class="scroll-content-pr">
        {!loading && ach.length !== 0 && ach.map((a) => (
            <div className="prog" style={{display: a.completed === 'completed'? 'none' : 'grid'}}>
                <img className="pr-img" src={a.img_url}/>
                <div className="pr-info">
                    <span className="pr-name">{a.title}</span>
                    <span className="pr-desc">{a.description}</span>
                    <div className="pr-money"> 
                        <img src="img/Moneta3 1.png" />
                        <span>  + {a.cost}</span>
                    </div>
                </div>
                <button className="pr-btn"
                 onClick={() => Claim(a._id, a.img_url, a.title, a.description, a.cost, a.completed)} 
                 id={`btn-${a._id}`}
             >
                 {a.claimed || a.completed ? 'Done' : 'Claim'} {/* Используем состояние для отображения текста */}
                </button>
            </div>
))}
        </div>
        </div>
        </div>
    </div>

    
)});

export default Profile;